import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CovidDataTrinidad from "../components/CovidDataTrinidad"

const IndexPage = () => (
  <Layout location={{href:"https://covidtracker.wjatt.com"}}>
    <SEO title="Home" />
    <CovidDataTrinidad />
  </Layout>
)

export default IndexPage
