import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: "hsl(353.3, 84.8%, 43.7%)",
      marginBottom: `1rem`,
      color: "black",
      padding: "5px",
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `.5rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0, display: "inline-block" }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <div className="nav" style={{
        display: "inline-block",
        float: "right"
      }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            marginRight: "2rem",
          }}
        >
          Home
        </Link>
        <Link
          to="/tips"
          style={{
            color: `white`,
            textDecoration: `none`,
            
          }}
        >
          Tips
        </Link>
      </div>
      
    </div>

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
