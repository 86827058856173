import React from "react"
import CSSStyles from "./currentdata.module.css"

export default function CurrentData({ current }) {
  return (
    <div>
      <div className={CSSStyles.countryHeader}>
        <div className={CSSStyles.countryName}>{current.country}</div>
        <div>
          <img className={CSSStyles.flag} src={current.countryInfo.flag} alt=""  />
        </div>
      </div>

      <div className={CSSStyles.dataTable}>
        <div>
          <div>Cases</div>
          <div>{current.cases}</div>
        </div>
        <div>
          <div>New Cases</div>
          <div>{current.todayCases}</div>
        </div>
        <div>
          <div>Deaths</div>
          <div>{current.deaths}</div>
        </div>
        <div>
          <div>Deaths Today</div>
          <div>{current.todayDeaths}</div>
        </div>
        <div>
          <div>Recovered</div>
          <div>{current.recovered}</div>
        </div>
        <div>
          <div>Active</div>
          <div>{current.active}</div>
        </div>
      </div>
    </div>
  )
}

CurrentData.defaultProps = {
  current: {
    country: "",
    flag: "",
    cases: "",
    todayCases: "",
    deaths: "",
    todayDeaths: "",
    recovered: "",
    active: "",
    countryInfo: {
      flag: "",
    },
  },
}
