/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children , location}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      style={{
        height: "100%",
        display: "block",
        gridTemplateRows: "1fr",
        gridTemplateRows: "80px auto 100px",
      }}
    >
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1rem`,
        }}
      >
      <div id="share-buttons" style={{float:"right"}}>
    

    
    <a href={"mailto:?Subject=Simple Share Buttons&amp;Body=I%20saw%20this%20and%20thought%20of%20you!%20"+location.href} >
        <img src="https://simplesharebuttons.com/images/somacro/email.png" style={styles.img} alt="Email" />
    </a>
 
    <a href={"http://www.facebook.com/sharer.php?u="+location.href} target="_blank" rel="noopener">
        <img src="https://simplesharebuttons.com/images/somacro/facebook.png" style={styles.img} alt="Facebook" />
    </a>
{/*   
    <a href="javascript:;" onClick={print}>
        <img src="https://simplesharebuttons.com/images/somacro/print.png" style={styles.img} alt="Print" />
    </a> */}
    
    <a href={"https://twitter.com/share?url="+location.href+"&amp;text=Covid%20Tracker%20TT&amp;#covidtrackertt"} target="_blank" rel="noopener">
        <img src="https://simplesharebuttons.com/images/somacro/twitter.png " style={styles.img} alt="Twitter" />
    </a>

</div>
        <main>{children}</main>
      </div>
      <footer
        style={{
          background: "hsl(353.3, 84.8%, 43.7%)",
          padding: "10px",
          color: "white",
          textDecoration: "none",
          marginTop: "150px",
        }}
      >
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `.5rem 1.0875rem`,
          }}
        >
          <div style={{ fontSize: ".8rem" }}>
            Data collected from John Hopkins University and other sources and
            updated every 15 minutes
          </div>
          <a
            href="https://github.com/damain"
            style={{
              color: "white",
              textDecoration: "none",
            }}
          >
            {" "}
            Created by Damain Joseph{" "}
          </a>
          <div>
            Email :{" "}
            <a
              href="mailto:damain@wjatt.com"
              style={{
                color: "white",
                textDecoration: "none",
              }}
            >
              damain@wjatt.com
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location:{
    href:"https://covidtracker.wjatt.com/"
  }
}

const styles = {
  img: {
    width: "35px",
    padding: "5px",
    border: "0",
    boxShadow: "0",
    display: "inline",
  },
}

export default Layout
