import React, { useEffect, useState } from "react"
import CurrentData from "./CurrentData"
import HistoryChart from "./HistoryChart"
import moment from "moment"
import refreshImage from "../images/refresh-black-18dp.svg"

export default function CovidDataTrinidad() {
  const [history, setHistory] = useState([])
  const [current, setCurrent] = useState({})
  const [data, setData] = useState({})
  const [lastRefresh, setLastRefresh] = useState(new Date())
  const [lastRefreshText, setLastRefreshText] = useState()
  const [refreshInterval, setRefreshInterval] = useState(null)
  useEffect(() => {
    const cbk = () => {
      setLastRefreshText(moment(lastRefresh).fromNow())
    }
    clearInterval(refreshInterval)
    setRefreshInterval( setInterval(cbk, 1000))
    return () => {
      clearInterval(refreshInterval)
    }
  }, [lastRefresh])
  useEffect(() => {
    const fetchData = async () => {

      let current = await (
        await fetch(
          "https://covidstatstt.firebaseio.com/current/Trinidad and Tobago.json"
        )
      ).json()
      setCurrent(current)
    }
    fetchData()
  }, [lastRefresh])

  useEffect(() => {
    const fetchData = async () => {
      //   let history = await (
      //     await fetch(
      //       "https://corona.lmao.ninja/v2/historical/Trinidad and Tobago"
      //     )
      //   ).json()
      //   let current = await (
      //     await fetch("https://covidstatstt.firebaseio.com/current/Trinidad and Tobago.json")
      //   ).json()

      let d = await (
        await fetch(
          "https://covidstatstt.firebaseio.com/history/Trinidad-and-Tobago.json"
        )
      ).json()
      //console.log(d)
      let res = Object.keys(d).map(key => {
        return d[key]
      })
      //console.log("response: ", res)
      setData(res)
      //Check if response is from same day
      const sameDay = (first, second) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()

      // reduce entries from db to separate arrays for chart
      const reducer = (acc, curr, index, arr) => {
        let first = new Date(curr.time)
        let second =
          index === arr.length - 1
            ? new Date(moment().add(1, "day"))
            : new Date(arr[index + 1].time)
        let today = new Date()
        if (!sameDay(first, second)) {
          //console.log(acc, curr)
          acc.time.push(moment(curr.time).format("MMM Do hh a"))
          acc.cases.push(curr.cases.total)
          acc.deaths.push(curr.deaths.total)
          acc.active.push(curr.cases.active)
          acc.recovered.push(curr.cases.recovered)
        }
        return acc
      }
      let hist = res.reduce(reducer, {
        time: [],
        cases: [],
        deaths: [],
        active: [],
        recovered: [],
      })
      //console.log(hist)
      setHistory(hist)
    }
    fetchData()
  }, [lastRefresh])

  return (
    <div>
      <div
        onClick={() => {
          setLastRefresh(new Date())
        }}
        style ={styles.refresh}
      >
        <img alt="click here to refresh" style={{marginBottom:"0px"}} src={refreshImage}/> <span>(last refreshed {lastRefreshText})</span>
      </div>
      {current.country !== undefined ? (

        <div>
          <h3>Todays Statistics</h3>
          <CurrentData current={current} />
        </div>
      ) : (
        "loading current data..."
      )}
      
      {history ? (
        <div style={{marginTop: "50px"}}>
        	<HistoryChart history={history} label="Confirmed Cases By Date"  />
        </div>
      ) : (
        "loading..."
      )}

      {/* {JSON.stringify(data)} */}
    </div>
  )
}

const styles = {
    refresh: {
        fontStyle: "italic",
        fontSize: ".8em",
        color: "firebrick"
    }
}
