import React, { useRef, useEffect, useState } from "react"
import Chart from "chart.js"

export default function HistoryChart({ history, label }) {
  const ctx = useRef("")
  const ctx2 = useRef("")
  const [chart, setChart] = useState(null)
  const [pieChart, setPieChart] = useState(null)
  const [dates, setDates] = useState([])
  const [cases, setCases] = useState({})
  const [bodyWidth, setBodyWidth] = useState(400)

  const generateChart = () => {
    //console.log("calculating chart")
    if (chart) {
      chart.destroy()
    }

    //console.log(JSON.stringify(dates))
    let c = new Chart(ctx.current, {
      type: "line",

      // The data for our dataset
      data: {
        labels: history.time,
        datasets: [
          {
            label: "Confirmed Cases",
            backgroundColor: "rgba(0, 145, 255, 0.1)",
            borderColor: "rgba(0, 145, 255, 1)",
            data: history.cases,
          },
          {
            label: "Active",
            backgroundColor: "rgba(253, 136, 27, 0.5)",
            borderColor: "rgba(253, 136, 27, 1)",
            data: history.active,
          },
          {
            label: "Deaths",
            backgroundColor: "rgba(255, 6, 60, 0.5)",
            borderColor: "rgb(255, 6, 60, 1)",
            data: history.deaths,
          },

          {
            label: "Recovered",
            backgroundColor: "rgba(77, 250, 71, 0.5)",
            borderColor: "rgba(77, 250, 71, 1)",
            data: history.recovered,
          },
        ],
      },
      options: {
        maintainAspectRatio: 0,
        title: {display: true, text:"The 'curves' or status over time"}
      },
    })
    return c
  }

  const generatePieChart = () => {
    //console.log("calculating chart")

    if (pieChart) {
      pieChart.destroy()
    }
    //console.log(JSON.stringify(dates))
    let c = new Chart(ctx2.current, {
      type: "doughnut",

      // The data for our dataset
      data: {
        labels: ["Active", "Deaths", "Recovered"],
        datasets: [
          {
            data: [
              history.active[history.active.length - 1],
              history.deaths[history.deaths.length - 1],
              history.recovered[history.recovered.length - 1],
            ],
            backgroundColor:["rgba(253, 136, 27, 0.5)", "rgba(255, 6, 60, 0.5)", "rgba(77, 250, 71, 0.5)"]
          },
        ],
      },
      options: {
        maintainAspectRatio: 0,
        title: {display: true, text:"Current Status of all cases"}
      },
    })
    return c
  }

  useEffect(() => {
    // let caseDates = history.cases.filter(d=>{
    //     if(history.timeline.cases[d] > 0){
    //         return true
    //     }
    // })
    // let cases = caseDates.map(d => history.cases[d])
    // let deaths = caseDates.map(d => history.timeline.deaths[d])
    // setDates(history.time)
    // setCases(histocases)
    // setDeaths(deaths)
    if(history.cases){
      setBodyWidth(document.querySelector("#chartwrapper").clientWidth  )
    setChart(generateChart())
    setPieChart(generatePieChart())}
  }, [history])

  useEffect(() => {
    function debounce(func, wait, immediate) {
      var timeout
      return function() {
        var context = this,
          args = arguments
        var later = function() {
          timeout = null
          if (!immediate) func.apply(context, args)
        }
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
      }
    }
    const updateChart = debounce(() => {
      //console.log("updating chart")
      //setChart(generateChart())
      //chart.update()
      setBodyWidth(document.querySelector("#chartwrapper").clientWidth  )
    }, 500)
    window.addEventListener("resize", updateChart)

    return () => {
      window.removeEventListener("resize", updateChart)
    }
  }, [cases])

  return (
    <div id="chartwrapper">
      <div
        
        style={{
          display:"inline-block",
          position: "relative",
          height: "40vh",
          width: bodyWidth < 600 ? bodyWidth+"px" : bodyWidth*.5+"px",
        }}
      >
        <canvas ref={ctx}></canvas>
      </div>
      <div
        style={{
          display:"inline-block",
          position: "relative",
          height: "40vh",
          width: bodyWidth < 600 ? bodyWidth+"px" : bodyWidth*.49+"px",
        }}
      >
        <canvas ref={ctx2}></canvas>
      </div>
    </div>
  )
}

HistoryChart.defaultProps = {
  history: {
    time: [],
    cases: [],
    deaths: [],
    recovered: [],
    active: [],
  },
  label: "",
}
